
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import InvestmentStrategyCard from "@/shared/components/InvestmentStrategyCard.vue";
import { mapGetters } from "vuex";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import { SelectedStrategy } from "@/shared/types/InvestmentStrategy";
import { showAlert } from "@/utils/helpers";

@Options({
  components: {
    SectionTitle,
    InvestmentStrategyCard,
    LeftStepBar
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    this.$store.dispatch("onboarding/getStrategies");
    this.$store.dispatch("questionnaire/getRiskProfiles");
    console.log(localStorage.getItem("wem_locale"));
  },
  computed: {
    strategies() {
      return this.$store.getters["onboarding/investmentStrategies"];
    },
    ...mapGetters({
      selectedStrategies: "onboarding/selectedStrategies",
      riskProfiles: "questionnaire/riskProfiles",
      investorName: "questionnaire/getInvestorName"
    }),
    isLocaleEn() {
      return typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'en';
    }
  },
  async created() {
    const profile = this.$store.state.onboarding.profile;
    if (profile && profile.risk_profile_id !== null) {
      await this.$store.dispatch('questionnaire/getRiskProfiles');
      this.findNameById(profile.risk_profile_id);
    }
  },
  methods: {
    saveSelectedStrategies() {
      if (this.selectedStrategies.length > 0) {
        const invalidStrategies: any = [];
      }
      this.$store.commit("onboarding/saveSelectedStrategies");
    },
    isStrategyValid(strategy: SelectedStrategy) {
      return strategy.investment_monthly >= strategy.min_investment;
    },
    async findNameById(id: number) {
      const riskProfilesString = localStorage.getItem("riskProfiles");
      console.log("Investor", riskProfilesString);
      if (riskProfilesString) {
        const riskProfiles = JSON.parse(riskProfilesString);
        riskProfiles.forEach((o: any) => {
          if (o.id === id) {
            if (typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'sk') {
              console.log("Investor", o.name);
              this.$store.commit("questionnaire/setInvestorName", o.name);
            } else {
              this.$store.commit("questionnaire/setInvestorName", o.name_en);
            }
          }
        });
      }
    },
    //
    // findNameById(id: number) {
    //   console.log("Investor", this.riskProfiles);
    //   this.riskProfiles.forEach((o:any) => {
    //     if (o.id === id) {
    //       this.$store.commit("questionnaire/setInvestorName", o.name);
    //       console.log("Investor", o.name);
    //       return (this.investor = o.name);
    //     }
    //   });
    // },
  }
})
export default class InvestmentStrategy extends Vue {
}
